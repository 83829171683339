import { Injectable } from '@angular/core';

import { Encryption } from '../classes/encryption';
import { USER_PROFILE } from './user-profile';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  userProfile = USER_PROFILE;

  constructor() { }

  // Set User Profile
  setUserProfile(data: any): void {
    localStorage.setItem('profile', data);

    this.setGlobalUserProfile();
  }

  // Unset User Profile
  unsetUserProfile(): void {
    localStorage.removeItem('profile');
  }

  // Get Profile From Storage
  getUserProfile(): any {
    const profile = localStorage.getItem('profile').toString();
    // console.log(profile);

    const profileData = (new Encryption()).decrypt(profile, 'this-is-secret');

    return JSON.parse(profileData);
  }

  // Update Profile Value
  updateUserProfileValue(key: string, value: any): void {
    const profile = this.getUserProfile();

    if (['full_name', 'avatar', 'role'].indexOf(key) > -1) {
      profile[key] = value;
      const profileData = (new Encryption()).encrypt(JSON.stringify(profile), 'this-is-secret');

      this.setUserProfile(profileData);
    }

    this.setGlobalUserProfile();
  }

  setGlobalUserProfile(): void {
    const profile = this.getUserProfile();

    this.userProfile.full_name = profile.full_name;
    this.userProfile.avatar = profile.avatar;
    this.userProfile.role = profile.role;
    this.userProfile.user_id = profile.id;
    this.userProfile.hh_agency_id = profile.hh_agency_id;
  }

  /**
   * Roles Methods. [ Set Role, Get Role, Has Role ]
   */
   setRoles(roles): void {
    localStorage.setItem('roles', JSON.stringify(roles));
  }

  getRoles(): any {
    return JSON.parse(localStorage.getItem('roles'));
  }

  hasRole(role): boolean {
    const roles = JSON.parse(localStorage.getItem('roles'));
    return roles.includes(role);
  }

}
