import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private apiEndpoints = API_ENDPOINTS;
  private timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(
    private http: HttpClient
  ) { }

  list(pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    params = params.append('timeZone', this.timeZone);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoints.provider.patients.list, { params });
  }

  twoWeekMovingAvgList(pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    params = params.append('timeZone', this.timeZone);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoints.provider.patients.moving_avg_list, { params });
  }

  detail(id): Observable<any> {
    let params = new HttpParams().append('timeZone', this.timeZone);
    return this.http.get(this.apiEndpoints.provider.patients.detail.replace('{id}', id), { params });
  }

  detailInfo(id): Observable<any> {
    let params = new HttpParams().append('timeZone', this.timeZone);
    return this.http.get(this.apiEndpoints.provider.patients.detail_info.replace('{id}', id), { params });
  }

  update(id, data): Observable<any> {
    return this.http.put(this.apiEndpoints.provider.patients.detail.replace('{id}', id), data);
  }

  discharge(id, data): Observable<any> {
    return this.http.put<any>(this.apiEndpoints.provider.patients.discharge.replace('{id}', id), data);
  }

  profile(id): Observable<any> {
    return this.http.get(this.apiEndpoints.provider.patients.profile.replace('{id}', id));
  }

  // Devices List.
  devicesList(id): Observable<any> {
    return this.http.get(this.apiEndpoints.provider.patients.devices.list.replace('{id}', id));
  }

  addDevice(data): Observable<any> {
    return this.http.post<any>(this.apiEndpoints.provider.patients.devices.store, data);
  }

  removeDevice(id, comment = ''): Observable<any> {
    return this.http.delete<any>(this.apiEndpoints.provider.patients.devices.destroy.replace('{id}', id), {
      body: {comment}
    });
  }

  // Text Logs List.
  textLogsList(patientId, pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoints.provider.patients.text_logs.replace('{id}', patientId), { params });
  }

  // Call Logs List.
  callLogsList(patientId, pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoints.provider.patients.call_logs.replace('{id}', patientId), { params });
  }

  // Patients Alerts Listing.
  patientAlertsList(patientId, pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoints.provider.patients.patient_alerts.replace('{id}', patientId), { params });
  }

  alertsList(pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoints.provider.patients.alerts, { params });
  }
  // Timer Logs List.
  timerLogsList(patientId, pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoints.provider.patients.time_log.list.replace('{id}', patientId), { params });
  }

  // Send Text
  sendText(userId, message: string) {
    return this.http.post(
      this.apiEndpoints.provider.patients.send_text.replace('{id}', userId),
      { message: message }
    );
  }

  alertsCount(patientUserId): Observable<any> {
    return this.http.get(this.apiEndpoints.provider.patients.alerts_count.replace('{id}', patientUserId));
  }

  // update clinic notes
  updateClinicNotes(userId, notes: string) {
    return this.http.post(
      this.apiEndpoints.provider.patients.update_clinic_notes.replace('{id}', userId),
      { notes: notes }
    );
  }

  careteamMembers(patientUserId) {
    return this.http.get(this.apiEndpoints.provider.patients.careteam_members.replace('{id}', patientUserId));
  }

  tasksList(patientUserId, pageIndex, pageSize, sortField, sortOrder, filters) {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length != undefined && filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }
    
    return this.http.get(this.apiEndpoints.provider.patients.tasks.list.replace('{id}', patientUserId),
      { params }
    );
  }

  getTransactionHistory(transactionId,carrier): Observable<any> {
    return this.http.post(this.apiEndpoints.provider.patients.transaction.history,{transactionId,carrier});
  }

  notAddressedReadingsList(pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }
    return this.http.get(this.apiEndpoints.provider.patients.readings.not_addressed, { params });
  }

  toggleAutomatedSms(patientUserId, data): Observable<any> {
    return this.http.post(this.apiEndpoints.provider.patients.toggleAutomatedSms.replace('{id}', patientUserId), data);
  }  
  sendBulkSms(message): Observable<any> {
    return this.http.post(this.apiEndpoints.provider.patients.sendBulkSms, message );
  }

  fetchPatientNotifications(id): Observable<any> {
    return this.http.get(this.apiEndpoints.provider.patients.notificationFetch.replace('{id}', id));
  }

  updatePatientNotifications(data:any): Observable<any> {
    return this.http.post(this.apiEndpoints.provider.patients.updateNotificationStatus,data);
  }

  updateReminderTime(data:any): Observable<any> {
    return this.http.post(this.apiEndpoints.provider.patients.reminderTimeUpdate,data);
  }

  updateMedicaidDetails(id, data): Observable<any> {
    return this.http.post(this.apiEndpoints.provider.patients.medicaid_details.replace('{id}', id), data);
  }

  newlyRegisteredList(pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoints.provider.new_patients_list, { params });
  }

  getRibbonDetail(id): Observable<any> {
    return this.http.get(this.apiEndpoints.provider.patients.ribbon_detail.replace('{id}', id));
  }

  checkCcmGoals(id): Observable<any> {
    return this.http.get(this.apiEndpoints.provider.patients.check_ccm_goals.replace('{id}', id));
  }

  updatePatientStatus(id, data): Observable<any> {
    return this.http.post(this.apiEndpoints.provider.patients.update_patient_status.replace('{id}', id), data);
  }

  getPatientsInsuranceList(sortField, sortOrder, filters): Observable<any> {
    let params = new HttpParams();

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    params = params.append('timeZone', this.timeZone);
    if (filters?.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }
    
    return this.http.get(this.apiEndpoints.provider.patient_insurance_list, { params });
  }
}
