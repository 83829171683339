import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { USER_PROFILE } from '../../services/user-profile';
import { PermissionService } from '../../services/permission.service';
import { Globals } from 'src/app/globals';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html',
    styles: [`
    .has-ribbon { top: 105px !important; }
    `]
})

export class SideNavComponent implements OnInit, OnChanges {

    @Input() showRibbon = false;

    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;
    isExpand : boolean;
    userProfile = USER_PROFILE;

    constructor( 
        private themeService: ThemeConstantService,
        private permission: PermissionService,
        public globals: Globals) {}

    ngOnInit(): void {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.getPermissions();               
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['showRibbon'].currentValue !== undefined && changes['showRibbon'].currentValue !== '' && changes['showRibbon'].currentValue !== null) {
        }
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }

    getPermissions(): void {
        this.permission.getLoggedInUserPermissions({}).subscribe({
          next: (data) => {
            if (data.data?.includes('device_readings'))
            { 
                let extraMenu = {
                    path: 'device-list',
                    title: 'Devices',
                    iconType: 'nzIcon',
                    iconTheme: 'outline',
                    icon: 'tablet',
                    submenu: []
                };
                this.menuItems.push(extraMenu);
            }
            if (data.data?.includes('device_tracking'))
            { 
                let extraMenu = {
                    path: 'device-shipping',
                    title: '',
                    iconType: 'fontawesome',
                    iconTheme: 'fas',
                    icon: 'fa-shipping-fast',
                    submenu: []
                };      
                this.menuItems.push(extraMenu);
            }
            if (data.data?.includes('bulk_sms')) { 
                this.globals.bulkMessagePermission = true;
            }
            if (data.data?.includes('task_manager')) {
                this.globals.taskManagerPermission = true;
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
      }
}
